import React, { useRef, useState } from "react";
import { Form } from "react-bootstrap";

const Editable = ({
                    editable,
                    text,
                    textProvider = (t) => (<h2>{t}</h2>),
                    type = "textarea",
                    placeholder,
                    setter,
                    ...props
                  }) => {
  const target = useRef(null);
  const [isEditing, setEditing] = useState(false);

  const preventDefault = (event) => {
    event.preventDefault();
    target.current.blur();
  }

  const handleChange = (event) => {
    setter(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.code === 'Escape') {
      target.current.blur();
    }
  };

  const className = editable ? "editable" : "";
  const style = editable ? {cursor: "ppinter"} : {}

  return (<section className={className} {...props}>
    {editable && isEditing ? (<div onBlur={() => setEditing(false)}>
      {textProvider(
        <Form onSubmit={preventDefault}>
          <Form.Group>
            <Form.Control ref={target} type={type} autoFocus={true} value={text || ""} onChange={handleChange} onKeyDown={handleKeyDown} />
          </Form.Group>
        </Form>
      )}
    </div> ) : (<div style={style} onClick={() => setEditing(editable)}>
      {textProvider(text || placeholder || "edit me...")}
    </div>)}
  </section>);

};

export default Editable;