import React from 'react';
import ReactDOM from 'react-dom';
import './bootstrap.min.css'
import App from "./App";
import netlifyIdentity from 'netlify-identity-widget';


window.netlifyIdentity = netlifyIdentity;
// You must run this once before trying to interact with the widget
netlifyIdentity.init();

ReactDOM.render(<App />, document.getElementById('root'));