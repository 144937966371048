import { createSlice } from '@reduxjs/toolkit'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: null,
    isAuthenticated: false,
  },
  reducers: {
    signIn: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    logOut: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
    }
  }
});

export const { signIn, logOut } = userSlice.actions;

export default userSlice.reducer;