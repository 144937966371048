import React from 'react';

import {Provider} from 'react-redux'
import store from './store'


import Header from './components/Header'
import Footer from './components/Footer'
import Public from './components/Public';
import Playbooks from "./components/playbooks/Playbooks";

import {Container, Row, Col, Nav} from "react-bootstrap";


import {
  BrowserRouter as Router,
  Route,
  Switch
} from 'react-router-dom';

import {LinkContainer} from 'react-router-bootstrap'

import "./App.css";

function AppInner() {
  return (<>
    <Row>
      <Col sm={3} md={2}>
        <Nav className="flex-column">
          <LinkContainer to={"/"}>
            <Nav.Link eventKey={"/home"}>
              Home
            </Nav.Link>
          </LinkContainer>
          <Nav.Item>
            <hr />
          </Nav.Item>
          <LinkContainer to={"/playbooks"}>
            <Nav.Link eventKey={"/playbooks"}>
              Playbooks
            </Nav.Link>
          </LinkContainer>
          <LinkContainer to={"/characters"}>
            <Nav.Link eventKey={"/characters"}>
              Player Characters
            </Nav.Link>
          </LinkContainer>
        </Nav>
      </Col>
      <Col sm={9} md={10}>
        <Switch>
          <Route path="/playbooks">
            <Playbooks />
          </Route>
          <Route path="/characters">
            <Public />
          </Route>
          <Route path={"/"}>
            <p>
              Welcome to Leviathan Refinery, a repository of Forged in the Dark resources.
            </p>
          </Route>
        </Switch>
      </Col>
    </Row>
  </>);
}

export default function App() {
  return (
      <Provider store={store}>
        <main>
          <Container>
            <Row className={"mt-4"}>
              <Col>
                <Header title="Leviathan Refinery" />
              </Col>
            </Row>
            <Router>
              <AppInner />
            </Router>
            <Footer />
          </Container>
        </main>
      </Provider>
  )
}
