import React, {useState} from 'react';
import { Button } from 'react-bootstrap';
import ScoundrelPlaybook from "./ScoundrelPlaybook";

export default function Playbooks() {

  const [playbook, setPlaybook] = useState({
    dirty: false,
    name: null,
    description: null,
    specialAbilities: [
      {
        name: "Veteran",
        evergreen: true,
        levels: 3,
        description: "Choose a special ability from another source."
      }
    ]
  });

  return (
    <>
      <h3>
        Create a Playbook
        { playbook.dirty && (
          <>
            {" "}
            <Button>Save</Button>
          </>
        )}
      </h3>
      <ScoundrelPlaybook
        playbookData={playbook}
        setter={(v) => setPlaybook({...v, dirty: true})}
        mode={"building"}
      />

    </>);
}