import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import Editable from "../Editable";
import SpecialAbility from "./SpecialAbility";

export default function ScoundrelPlaybook({ playbookData = {}, characterData = {}, setter, mode}) {

  const building = (mode === "building");


  return (<Container className={"playbook"}>
    <Row>
      <Col>

      </Col>
      <Col className={"panel"}>
        <Container>
          <Row>
            <Col lg={6}>
              <Editable
                editable={building}
                text={playbookData.name}
                textProvider={(t) => <h2>{t}</h2>}
                placeholder={"Name"}
                setter={(name) => { setter({ ...playbookData, name }) }}
              />
            </Col>
            <Col lg={6}>
              <Editable
                editable={building}
                text={playbookData.description}
                textProvider={(t) => <h6>{t}</h6>}
                placeholder={"Description"}
                setter={(description) => { setter({ ...playbookData, description }) }}
              />
            </Col>
          </Row>
        </Container>
      </Col>
      <Col>

      </Col>
    </Row>
    <Row>
      <Col>

      </Col>
      <Col className={"panel"}>
        <h5>Playbook</h5>
        <h6>Special Abilities</h6>
        {playbookData.specialAbilities.map(sa => {
          return <SpecialAbility key={sa.name} ability={sa} editable={building} />;
        })}
      </Col>
      <Col>
        <h5>Playbook XP</h5>
        <h5>Insight</h5>
        <h5>Prowess</h5>
        <h5>Resolve</h5>
      </Col>
    </Row>


  </Container>);
}